body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
}

header,
footer {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

nav a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
}

main {
  padding: 20px;
}

section {
  margin-bottom: 40px;
}

h1,
h2 {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

